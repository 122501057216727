import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
import { throwError } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class CommonService {
    dataTable: any;
    private baseUrl = environment.baseUrl;
    headers: any;
    constructor(private router: Router, private toastr: ToastrService, private http: HttpClient,) {
      
    }

    // Get API
    doGet(url: any, qp: any) {
        console.log("url...", url, qp)
        this.headers = new HttpHeaders({
            'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*',
            CmpCode: qp.head.CmpCode, PlantCode: qp.head.PlantCode, ProjCode: qp.head.ProjCode, UserId: qp.head.UserId
        });
        return this.http.get(this.baseUrl + url, { params: qp.param, headers: this.headers, responseType: "text" })
            .pipe(map(this.extractData), catchError(this.handleErrorObservable));
    }
    doGetBlob(url: any, qp: any) {
        console.log('url', url, qp)
        return this.http.get<any>(this.baseUrl + url, { params: qp.param, responseType: 'blob' as 'json' })
            .pipe(map(this.extractData), catchError(this.handleErrorObservable))
    }
    // Post API
    doPost(url: any, data: any) {
        this.headers = new HttpHeaders({
            'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*',
            CmpCode: data.head.CmpCode, PlantCode: data.head.PlantCode, ProjCode: data.head.ProjCode, UserId: data.head.UserId
        });
        console.log(">>>>>>>>>",this.baseUrl + url, data.data, data.param, this.headers)
        return this.http.post(this.baseUrl + url, data.data, { params: data.param, headers: this.headers, responseType: "text" })
            .pipe(map(this.extractData), catchError(this.handleErrorObservable));
    }

    // Post Method for Files(.xlsx, .csv)
    // doPostFile(url: any, data: any) {
    //     return this.http.post(this.baseUrl + url, data.data, { params: data.param, headers: this.headers, responseType: "text" })
    //         .pipe(map(this.extractData), catchError(this.handleErrorObservable));
    // }

    // Post Method for Files(.xlsx, .csv)
    doPostFile(url: any, data: any) {
        console.log('put', url, data)
        this.headers = new HttpHeaders({
            // 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*',
            CmpCode: data.head.CmpCode, PlantCode: data.head.PlantCode, ProjCode: data.head.ProjCode, UserId: data.head.UserId
        });
        return this.http.post(this.baseUrl + url, data.data, { params: data.param, headers: this.headers, responseType: "text" })
            .pipe(map(this.extractData), catchError(this.handleErrorObservable));
    }
    // Put Method for Files(.xlsx, .csv)
    // doPutFile(url: any, data: any) {
    //     console.log(url,data.data);
    //     this.headers = new HttpHeaders({
    //         // 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*',
    //         CmpCode: data.head.CmpCode, PlantCode: data.head.PlantCode, ProjCode: data.head.ProjCode, UserId: data.head.UserId
    //     });
    //     return this.http.put(this.baseUrl + url, data.data, { params: data.param, headers: this.headers, responseType: "text" })
    //         .pipe(
    //             map(this.extractData),
    //             catchError(this.handleErrorObservable)
    //         );
    // }

    doPutFile(url: any, data: any) {
        console.log(url,data.data);
        this.headers = new HttpHeaders({
            CmpCode: data.head?.CmpCode || '',
            PlantCode: data.head?.PlantCode || '',
            ProjCode: data.head?.ProjCode || '',
            UserId: data.head?.UserId || ''
        });
    
        return this.http.put(this.baseUrl + url, data.data, {
            params: data.param,
            headers: this.headers,
            responseType: "text"
        })
        .pipe(
            map(this.extractData),
            catchError(this.handleErrorObservable)
        );
    }

    // Put API
    doPut(url: any, data: any) {
        this.headers = new HttpHeaders({
            'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*',
            CmpCode: data.head.CmpCode, PlantCode: data.head.PlantCode, ProjCode: data.head.ProjCode, UserId: data.head.UserId
        });
        console.log('put', url, data)
        return this.http.put(this.baseUrl + url, data.data, { params: data.param, headers: this.headers, responseType: "text" })
            .pipe(map(this.extractData), catchError(this.handleErrorObservable));
    }

    // Delete API
    doDelete(url: any, data: any) {

        console.log('URL', url, data)
        this.headers = new HttpHeaders({
            'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*',
            CmpCode: data.head.CmpCode, PlantCode: data.head.PlantCode, ProjCode: data.head.ProjCode, UserId: data.head.UserId
        });
       
        return this.http
            .delete(this.baseUrl + url, { params: data.param, headers: this.headers, responseType: "text" })
            .pipe(map(this.extractData), catchError(this.handleErrorObservable));
    }

    // Response API
    private extractData(res: Response | any) {
        var body = res;
        try {
            return JSON.parse(body);
        } catch (e) {
            return { data: body };
        }
    }
    // Error Handaler API
    private handleErrorObservable(error: Response | any) {
        console.log(error)
        if ((error.status >= 400 && error.status < 500) || error.status === 0) {
            let erroroon = error.message;
            return throwError(() => new Error(erroroon));
        }

        else
            if (error.status === 500 || error.status === 600) {
                error.status = JSON.stringify(error.status);
                let erroroon = error.status + " " + "Contact to help Desk" + " " + error.error;
                return throwError(() => new Error(erroroon));
            }

            else {
                error.status = JSON.stringify(error.status);
                let erroroon = error.status + " " + error.error;
                return throwError(() => new Error(erroroon));
            }
    }

    // Success Toastr
    showSuccess(msg: any) {
        this.toastr.success(msg, "Success!", {
            progressBar: true,
            progressAnimation: "decreasing",
            closeButton: true
        })
    }

    // Notification Toastr
    showNotification(msg: any) {
        this.toastr.info(msg, "Notification", {
            progressBar: true,
            progressAnimation: "decreasing",
            closeButton: true
        });
    }

    // Error Toastr
    showError(msg: any) {
        this.toastr.error(msg, "Error!", {
            progressBar: true,
            progressAnimation: "decreasing",
            closeButton: true
        })
    }



    // Logout User and expired session.
    logout() {
        sessionStorage.removeItem('token');
        this.router.navigate(['login']);
    }





    // ***************************** Common Function ********************************* //

    //convert the input file into base64 string
    readAsDataURL = (inputFile: any) => {
        var reader = new FileReader();
        return new Promise((resolve, reject) => {
            reader.onerror = () => {
                reader.abort();
                reject(new DOMException("Problem parsing input file."));
            };
            reader.onload = () => {
                resolve(reader.result);
            };
            reader.readAsDataURL(inputFile);
        });
    };
    // Remove whitespace and Datasource defaults to lowercase matches
    removeWhitespaceFilter(filterValue: string) {
        return new Promise((resolve, reject) => {
            filterValue = filterValue.trim(); // Remove whitespace
            filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
            resolve(filterValue)
        })
    }
    // Filter only deleted items
    async filterIsDeleted(data: any) {
        return new Promise((resolve, reject) => {
            resolve(data.filter((obj: any) => obj.isDeleted == false));
        })
    }
    // Filter only empty or null items
    async filterIsEmpltyElement(data: any) {
        return new Promise((resolve, reject) => {
            resolve(data.filter((obj: any) => obj != null));
        })
    }

    async filterIsEmpltyClientBill(data: any) {
        return new Promise((resolve, reject) => {
            resolve(data.filter((obj: any) => obj.BillQuantity));
        })
    }
    // Filter BOQ List
    async getFilterBOQ(data: any) {
        var arr: any = [];
        return new Promise((resolve, reject) => {
            data.forEach((element: any) => {
                arr.push({ cmpCode: element.cmpCode, plantCode: element.plantCode, projCode: element.projCode })
            });
            resolve(arr);
        })
    }
    // Filter BOQ List
    async getFilterBOQApprover(data: any) {
        var arr: any = [];
        return new Promise((resolve, reject) => {
            data.forEach((element: any) => {
                arr.push({ cmpCode: element.cmpCode, plantCode: element.plantCode, projCode: element.projCode, version: element.version })
            });
            resolve(arr);
        })
    }
    // Filter only is qty or is unit  items
    async filterActSubSeq(data: any) {
        return new Promise((resolve, reject) => {
            resolve(data.filter((obj: any) => obj.quntity != null && obj.displayUnit != null || !obj.displayUnit && obj.quntity!));
        })
    }


    // Sort Material
    async sortMaterial(data: any) {
        var arr: any = [];
        return new Promise((resolve, reject) => {
            data.forEach((element: any) => {
                if (element.subMaterial.length > 0) {
                    element.subMaterial.forEach((element1: any) => {
                        if (element1.materialDetails.length > 0) {
                            element1.materialDetails.forEach((element2: any) => {
                                arr.push({
                                    cmpCode: element2.cmpCode,
                                    descripption: element2.descripption,
                                    isDeleted: element2.isDeleted,
                                    materialDetailID: element2.materialDetailID,
                                    materialID: element2.materialID,
                                    materialSubID: element2.materialSubID,
                                })
                            });
                        }
                    });
                }
            });
            resolve(arr);
        })
    }
    // Sort Labour
    async sortLabour(data: any) {
        var arr: any = [];
        return new Promise((resolve, reject) => {
            data.forEach((element: any) => {
                if (element.labourDetails.length > 0) {
                    element.labourDetails.forEach((element1: any) => {
                        arr.push({
                            cmpCode: element1.cmpCode,
                            descripption: element1.descripption,
                            isDeleted: element1.isDeleted,
                            labourDetailsID: element1.labourDetailsID,
                            labourID: element1.labourID
                        })
                    });
                }
            });
            resolve(arr);
        })
    }

    // Sort Apply Supply
    async sortApplySupply(data: any) {
        var arr: any = [];
        return new Promise((resolve, reject) => {
            data.forEach((element: any) => {
                if (element.applySupplyDetails.length > 0) {
                    element.applySupplyDetails.forEach((element1: any) => {
                        arr.push({
                            applySupplyDetailsID: element1.applySupplyDetailsID,
                            applySupplyID: element1.applySupplyID,
                            cmpCode: element1.cmpCode,
                            descripption: element1.descripption,
                            isDeleted: element1.isDeleted
                        })
                    });
                }
            });
            resolve(arr);
        })
    }

    // Create JSON for Excel View and Download.
    // async getExcelJSONList(data: any) {
    //     var arr: any = [];
    //     var actGroupId = "";
    //     var actSeqNo = "";
    //     var displaySequence = "";
    //     var actDesc = "";
    //     var displayUnit = ""
    //     var quntity = "";
    //     var rate = "";
    //     var amount: any = "";
    //     return new Promise((resolve, reject) => {

    //         for (let i = 0; i < data.length; i++) {
    //             actGroupId = ""; actSeqNo = ""; displaySequence = ""; actDesc = ""; displayUnit = ""; quntity = ""; rate = ""; amount = "";
    //             if (data[i].actGroupId) { actGroupId = data[i].actGroupId }
    //             if (data[i].actSeqNo) { actSeqNo = data[i].actSeqNo }
    //             if (data[i].displaySequence) { displaySequence = data[i].displaySequence }
    //             if (data[i].actGroupDesc) { actDesc = data[i].actGroupDesc }
    //             if (data[i].displayUnit) { displayUnit = data[i].displayUnit }
    //             if (data[i].quntity && data[i].rate) {
    //                 quntity = data[i].quntity;
    //                 rate = data[i].rate;
    //                 amount = data[i].quntity * data[i].rate;
    //             }
    //             arr.push({
    //                 "actGroupId": actGroupId,
    //                 "actSeqNo": actSeqNo,
    //                 "displaySequence": displaySequence,
    //                 "actDesc": actDesc,
    //                 "displayUnit": displayUnit,
    //                 "quntity": quntity,
    //                 "rate": rate,
    //                 "amount": Number(amount).toFixed(2)
    //             })
    //             if (data[i].details.length > 0) {
    //                 for (let j = 0; j < data[i].details.length; j++) {

    //                     actGroupId = ""; actSeqNo = ""; displaySequence = ""; actDesc = ""; displayUnit = ""; quntity = ""; rate = ""; amount = "";
    //                     if (data[i].details[j].actGroupId) {
    //                         actGroupId = data[i].details[j].actGroupId
    //                     }
    //                     if (data[i].details[j].actSeqNo) { actSeqNo = data[i].details[j].actSeqNo }
    //                     if (data[i].details[j].displaySequence) { displaySequence = data[i].details[j].displaySequence }
    //                     if (data[i].details[j].actDesc) { actDesc = data[i].details[j].actDesc }
    //                     if (data[i].details[j].displayUnit) { displayUnit = data[i].details[j].displayUnit }
    //                     if (data[i].details[j].quntity && data[i].details[j].rate) { quntity = data[i].details[j].quntity; rate = data[i].details[j].rate; amount = data[i].details[j].quntity * data[i].details[j].rate }
    //                     arr.push({
    //                         "actGroupId": actGroupId,
    //                         "actSeqNo": actSeqNo,
    //                         "displaySequence": displaySequence,
    //                         "actDesc": actDesc,
    //                         "displayUnit": displayUnit,
    //                         "quntity": quntity,
    //                         "rate": rate,
    //                         "amount": Number(amount).toFixed(2)
    //                     })
    //                 }
    //             }
    //         }
    //         resolve(arr);
    //     })
    // }


    // Create JSON for Excel View and Download.
    async getExcelJSONList(data: any) {
        var arr: any = [];
        var actGroupId = "";
        var displaysequence = "";
        var actSeqNo = "";
        var actGroupDesc = "";
        var description = "";
        var actDesc = "";
        var quntity = "";
        var unitCode = "";
        var rate = "";
        var amount: any = "";
        return new Promise((resolve, reject) => {
            for (let i = 0; i < data.header.length; i++) {
                actGroupId = ""; displaysequence = ""; actSeqNo = ""; actGroupDesc = ""; unitCode = ""; quntity = ""; rate = ""; amount = "";
                if (data.header[i].actGroupId) { actGroupId = data.header[i].actGroupId; }
                if (data.header[i].displaysequence) { displaysequence = data.header[i].displaysequence }
                if (data.header[i].actSeqNo) { actSeqNo = data.header[i].actSeqNo }
                if (data.header[i].actGroupDesc) { actGroupDesc = data.header[i].actGroupDesc }
                if (data.header[i].unitCode) { unitCode = data.header[i].unitCode }
                if (data.header[i].quntity && data.header[i].rate) {
                    quntity = data.header[i].quntity;
                    rate = data.header[i].rate;
                    amount = data.header[i].quntity * data.header[i].rate;
                }
                arr.push({
                    "actGroupId": actGroupId,
                    "displaysequence": displaysequence,
                    "actSeqNo": actSeqNo,
                    "actGroupDesc": actGroupDesc,
                    "unitCode": unitCode,
                    "quntity": quntity,
                    "rate": rate,
                    "amount": ""
                })


                for (let j = 0; j < data.header[i].subHeads.length; j++) {

                    actGroupId = ""; displaysequence = ""; actSeqNo = ""; actGroupDesc = ""; unitCode = ""; quntity = ""; rate = ""; amount = ""
                    if (data.header[i].subHeads[j].actGroupId) { actGroupId = data.header[i].subHeads[j].actGroupId }
                    if (data.header[i].subHeads[j].displaysequence) { displaysequence = data.header[i].subHeads[j].displaysequence }
                    if (data.header[i].subHeads[j].actSeqNo) { actSeqNo = data.header[i].subHeads[j].actSeqNo }
                    if (data.header[i].subHeads[j].description) { description = data.header[i].subHeads[j].description }
                    if (data.header[i].subHeads[j].unitCode) { unitCode = data.header[i].subHeads[j].unitCode }
                    if (data.header[i].subHeads[j].quntity && data.header[i].subHeads[j].rate) { quntity = data.header[i].subHeads[j].quntity; rate = data.header[i].subHeads[j].rate; amount = data.header[i].subHeads[j].quntity * data.header[i].subHeads[j].rate }
                    arr.push({
                        "actGroupId": "",
                        "displaysequence": displaysequence,
                        "actSeqNo": actSeqNo,
                        "actGroupDesc": description,
                        "unitCode": unitCode,
                        "quntity": quntity,
                        "rate": rate,
                        "amount": ""
                    })

                    for (let k = 0; k < data.header[i].subHeads[j].details.length; k++) {

                        actGroupId = ""; displaysequence = ""; actSeqNo = ""; actGroupDesc = ""; unitCode = ""; quntity = ""; rate = ""; amount = "";
                        if (data.header[i].subHeads[j].details[k].actGroupId) { actGroupId = data.header[i].subHeads[j].details[k].actGroupId }
                        if (data.header[i].subHeads[j].details[k].displaysequence) { displaysequence = data.header[i].subHeads[j].details[k].displaysequence }
                        if (data.header[i].subHeads[j].details[k].actSeqNo) { actSeqNo = data.header[i].subHeads[j].details[k].actSeqNo }
                        if (data.header[i].subHeads[j].details[k].actDesc) { actDesc = data.header[i].subHeads[j].details[k].actDesc }
                        if (data.header[i].subHeads[j].details[k].unitCode) { unitCode = data.header[i].subHeads[j].details[k].unitCode }
                        if (data.header[i].subHeads[j].details[k].quntity && data.header[i].subHeads[j].details[k].rate) { quntity = data.header[i].subHeads[j].details[k].quntity; rate = data.header[i].subHeads[j].details[k].rate; amount = data.header[i].subHeads[j].details[k].quntity * data.header[i].subHeads[j].details[k].rate }
                        arr.push({
                            "actGroupId": "",
                            "displaysequence": displaysequence,
                            "actSeqNo": actSeqNo,
                            "actGroupDesc": actDesc,
                            "unitCode": unitCode,
                            "quntity": quntity,
                            "rate": rate,
                            "amount": Number(amount).toFixed(2)
                        })


                    }
                }
                // }
            }
            resolve(arr);
        })
    }


    // create JSON for EXcel View ANd download Apply Supply Rates
    async getExcelJSONapplySupplyRates(data: any) {
        var arr: any = [];
        var applySupplyID = "";
        var applySupplyDetailsID = "";
        var descripption = "";
        var standardrUnitCode = "";
        var rate = "";

        return new Promise((resolve, reject) => {
            for (let i = 0; i < data.applySupplyRates.length; i++) {
                applySupplyID = ""; applySupplyDetailsID = ""; descripption = ""; standardrUnitCode = ""; rate = "";
                if (data.applySupplyRates[i].applySupplyID) { applySupplyID = data.applySupplyRates[i].applySupplyID; }
                if (data.applySupplyRates[i].applySupplyDetailsID) { applySupplyDetailsID = data.applySupplyRates[i].applySupplyDetailsID }
                if (data.applySupplyRates[i].descripption) { descripption = data.applySupplyRates[i].descripption }
                if (data.applySupplyRates[i].standardrUnitCode) { standardrUnitCode = data.applySupplyRates[i].standardrUnitCode }
                if (data.applySupplyRates[i].rate) { rate = data.applySupplyRates[i].rate }

                arr.push({
                    "applySupplyID": applySupplyID,
                    "applySupplyDetailsID": applySupplyDetailsID,
                    "descripption": descripption,
                    "standardrUnitCode": standardrUnitCode,
                    "rate": rate || 0,

                })

            }
            resolve(arr);
        })
    }


    // create JSON for EXcel View ANd download Material Rates List
    async getExcelMaterialRatesList(data: any) {
        var arr: any = [];
        var materialID = "";
        var materialSubID = "";
        var materialDetailID = "";
        var descripption = "";
        var standardrUnitCode = "";
        var rate = "";

        return new Promise((resolve, reject) => {
            for (let i = 0; i < data.materialRates.length; i++) {

                materialID = ""; materialSubID = ""; materialDetailID = ""; descripption = ""; standardrUnitCode = ""; rate = "";
                if (data.materialRates[i].materialID) { materialID = data.materialRates[i].materialID; }
                if (data.materialRates[i].materialSubID) { materialSubID = data.materialRates[i].materialSubID; }

                if (data.materialRates[i].materialDetailID) { materialDetailID = data.materialRates[i].materialDetailID }
                if (data.materialRates[i].descripption) { descripption = data.materialRates[i].descripption }
                if (data.materialRates[i].standardrUnitCode) { standardrUnitCode = data.materialRates[i].standardrUnitCode }
                if (data.materialRates[i].rate) { rate = data.materialRates[i].rate }

                arr.push({
                    "materialID": materialID,
                    "materialSubID": materialSubID,
                    "materialDetailID": materialDetailID,
                    "descripption": descripption,
                    "standardrUnitCode": standardrUnitCode,
                    "rate": rate || 0,

                })

            }
            resolve(arr);
        })
    }

    // create JSON for EXcel View ANd download Labour Rates List
    async getExcelLabourRatesList(data: any) {
        var arr: any = [];
        var labourID = "";
        var labourDetailsID = "";
        var descripption = "";
        var standardrUnitCode = "";
        var rate = "";

        return new Promise((resolve, reject) => {
            for (let i = 0; i < data.labourRates.length; i++) {

                labourID = ""; labourDetailsID = ""; descripption = ""; standardrUnitCode = ""; rate = "";
                if (data.labourRates[i].labourID) { labourID = data.labourRates[i].labourID; }
                if (data.labourRates[i].labourDetailsID) { labourDetailsID = data.labourRates[i].labourDetailsID; }
                if (data.labourRates[i].descripption) { descripption = data.labourRates[i].descripption }
                if (data.labourRates[i].standardrUnitCode) { standardrUnitCode = data.labourRates[i].standardrUnitCode }
                if (data.labourRates[i].rate) { rate = data.labourRates[i].rate }
                arr.push({
                    "labourID": labourID,
                    "labourDetailsID": labourDetailsID,
                    "descripption": descripption,
                    "standardrUnitCode": standardrUnitCode,
                    "rate": rate || 0,

                })

            }
            resolve(arr);
        })
    }


}
