import { NativeDateAdapter } from '@angular/material/core';

// Define the custom date format
export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD-MM-YYYY',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

// Create a custom date adapter
export class MyDateAdapter extends NativeDateAdapter {
  format(date: Date, displayFormat: string): string {
    if (displayFormat === 'input') {
      const day = this._twoDigit(date.getDate());
      const month = this._twoDigit(date.getMonth() + 1);
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    } else {
      return date.toDateString();
    }
  }

  private _twoDigit(n: number): string {
    return (n < 10 ? '0' : '') + n;
  }
}
