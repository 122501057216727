import { Injectable } from '@angular/core';
import { CommonService } from './common.service';
import { data } from 'jquery';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiCallService {
  http: any;

  constructor(private commonService: CommonService) { }

  get WindowRef() { return window }
  // All Masters
  getAccountGroup = (data: any) => this.commonService.doGet('AccountGroup' + '/' + data.CmpCode, data);

  getCountry = (data: any) => this.commonService.doGet('Country', data);

  getState = (data: any) => this.commonService.doGet('State', data);
  getStateId = (data: any) => this.commonService.doGet('State' + '/' + data.countryCode, data);

  getCompany = (data: any) => this.commonService.doGet('CompanyDetails', data);
  addCompany = (data: any) => this.commonService.doPost('CompanyDetails', data);
  updateCompany = (data: any) => this.commonService.doPut('CompanyDetails' + '/' + data.data.oldCmpCode, data);
  deleteCompany = (data: any) => this.commonService.doDelete('CompanyDetails' + '/' + data.CmpCode, data);

  getPlant = (data: any) => this.commonService.doGet('Plant', data);
  getPlantId = (data: any) => this.commonService.doGet('Plant' + '/' + data.head.CmpCode, data);
  addPlant = (data: any) => this.commonService.doPost('Plant', data);
  updatePlant = (data: any) => this.commonService.doPut('Plant' + '/' + data.CmpCode + '/' + data.plantCode, data);
  deletePlant = (data: any) => this.commonService.doDelete('Plant' + '/' + data.CmpCode + '/' + data.plantCode, data);

  getBusinessPartnerByCompPlant = (data: any) => this.commonService.doGet('BusinessPartner' + '/' + 'ByCompPlant', data);
  getBusinessPartner = (data: any) => this.commonService.doGet('BusinessPartner', data);
  addBusinessPartner = (data: any) => this.commonService.doPost('BusinessPartner', data);
  updateBusinessPartner = (data: any) => this.commonService.doPut('BusinessPartner' + '/' + data.head.bpCode, data);
  deleteBusinessPartner = (data: any) => this.commonService.doDelete('BusinessPartner' + '/'  + data.head.bpCode, data);

  getEmployeeId = (data: any) => this.commonService.doGet('Employee' + '/' + data.head.CmpCode, data);
  addEmployee = (data: any) => this.commonService.doPost('Employee', data);
  updateEmployee = (data: any) => this.commonService.doPut('Employee' + '/' + data.head.CmpCode + '/' + data.head.bpCode, data);
  deleteEmployee = (data: any) => this.commonService.doDelete('Employee' + '/' + data.head.CmpCode + '/' + data.head.bpCode, data);

  getProject = (data: any) => this.commonService.doGet('Project', data);
  getProjectId = (data: any) => this.commonService.doGet('Project' + '/' + data.head.CmpCode, data);
  getProjectGetID = (data: any) => this.commonService.doGet('Project/GetID', data)
  addProject = (data: any) => this.commonService.doPost('Project', data);
  updateProject = (data: any) => this.commonService.doPut('Project' , data);
  deleteProject = (data: any) => this.commonService.doDelete('Project', data);
  getApproving = (data: any) => this.commonService.doGet('Approving' + '/' + data.head.CmpCode, data)

  // getBOQUpload = (data: any) => this.commonService.doGet('BOQ', data);
  getISBOQPresent = (data: any) => this.commonService.doGet('BOQ' + '/' + data.head.CmpCode + "/" + data.head.plantCode + "/" + data.head.projCode, data);
  getBOQUploadId = (data: any) => this.commonService.doGet('BOQ', data);
  addBOQUpload = (data: any) => this.commonService.doPost('BOQ', data);
  // updateBOQUpload = (data: any) => this.commonService.doPut('BOQ' + '/' + data.head.CmpCode + '/' + data.head.plantCode + '/' + data.head.projCode, data);
  deleteBOQUpload = (data: any) => this.commonService.doDelete('BOQ' + '/' + data.head.boqid, data);
  addBOQ = (data: any) => this.commonService.doPostFile('BOQ/ExcelUpload' , data);
  updateBOQUploaded = (data: any) => this.commonService.doPutFile('BOQ/ExcelUpload'+'/' +data.head.boqId,data);
  getUpBOQ = (data: any) => this.commonService.doGet('BOQ', data)
  updateBoqApproved = (data: any) => this.commonService.doPut('BOQ/Approved' + '/' + data.head.boqId + '/' + data.head.Movement, data)


  getProjectType = (data: any) => this.commonService.doGet('ProjectType', data);
  getProjectTypeId = (data: any) => this.commonService.doGet('ProjectType' + '/' + data.head.CmpCode, data);
  addProjectType = (data: any) => this.commonService.doPost('ProjectType', data);
  updateProjectType = (data: any) => this.commonService.doPut('ProjectType' + '/' + data.head.CmpCode + '/' + data.head.ID, data);
  deleteProjectType = (data: any) => this.commonService.doDelete('ProjectType' + '/' + data.head.CmpCode + '/' + data.head.ID, data);

  getProjectDesignation = (data: any) => this.commonService.doGet('ProjectDesignation', data);
  getProjectDesignationById = (data: any) => this.commonService.doGet('ProjectDesignation' + '/' + data.head.CmpCode, data);
  addProjectDesignation = (data: any) => this.commonService.doPost('ProjectDesignation', data);
  updateProjectDesignation = (data: any) => this.commonService.doPut('ProjectDesignation' + '/' + data.head.CmpCode + '/' + data.head.ID, data);
  deleteProjectDesignation = (data: any) => this.commonService.doDelete('ProjectDesignation' + '/' + data.head.CmpCode + '/' + data.head.ID, data);

  getExecutionType = (data: any) => this.commonService.doGet('ActivityType', data);
  getExecutionTypeById = (data: any) => this.commonService.doGet('ActivityType' + '/' + data.head.CmpCode, data);
  addExecutionType = (data: any) => this.commonService.doPost('ActivityType', data);
  updateExecutionType = (data: any) => this.commonService.doPut('ActivityType' + '/' + data.head.CmpCode + '/' + data.head.ID, data);
  deleteExecutionType = (data: any) => this.commonService.doDelete('ActivityType' + '/' + data.head.CmpCode + '/' + data.head.ID, data);

  getActivityHead = (data: any) => this.commonService.doGet('activityhead' + '/' + data.head.CmpCode, data);
  getActivityHeadBOQ = (data: any) => this.commonService.doGet('activityhead' + '/' + data.head.CmpCode, data);

  getAcountGroup = (data: any) => this.commonService.doGet('AccountGroup', data);
  getAccountGroupById = (data: any) => this.commonService.doGet('AccountGroup' + '/' + data.CmpCode, data);
  addAccountGroup = (data: any) => this.commonService.doPost('AccountGroup', data);
  updateAccountGroup = (data: any) => this.commonService.doPut('AccountGroup' + '/' + data.head.CmpCode + '/' + data.head.ID, data);
  deleteAccountGroup = (data: any) => this.commonService.doDelete('AccountGroup' + '/' + data.head.CmpCode + '/' + data.head.ID, data);

  getunitMaster = (data: any) => this.commonService.doGet('unitMaster', data);
  getunitMasterById = (data: any) => this.commonService.doGet('unitMaster' + '/' + data.head.CmpCode, data);
  addunitMaster = (data: any) => this.commonService.doPost('unitMaster', data);
  updateunitMaster = (data: any) => this.commonService.doPut('unitMaster' + '/' + data.head.CmpCode + '/' + data.head.ID, data);
  deleteunitMaster = (data: any) => this.commonService.doDelete('unitMaster' + '/' + data.head.CmpCode + '/' + data.head.ID, data);

  getDesignation = (data: any) => this.commonService.doGet('Designation', data);
  getDesignationById = (data: any) => this.commonService.doGet('Designation' + '/' + data.head.CmpCode, data);
  addDesignation = (data: any) => this.commonService.doPost('Designation', data);
  updateDesignation = (data: any) => this.commonService.doPut('Designation' + '/' + data.head.CmpCode + '/' + data.head.ID, data);
  deleteDesignation = (data: any) => this.commonService.doDelete('Designation' + '/' + data.head.CmpCode + '/' + data.head.ID, data);

  getDepartment = (data: any) => this.commonService.doGet('Department', data);
  getDepartmentById = (data: any) => this.commonService.doGet('Department' + '/' + data.head.CmpCode, data);
  addDepartment = (data: any) => this.commonService.doPost('Department', data);
  updateDepartment = (data: any) => this.commonService.doPut('Department' + '/' + data.head.CmpCode + '/' + data.head.ID, data);
  deleteDepartment = (data: any) => this.commonService.doDelete('Department' + '/' + data.head.CmpCode + '/' + data.head.ID, data);

  getLabourMaster = (data: any) => this.commonService.doGet('LabourMaster', data);
  getLabourMasterById = (data: any) => this.commonService.doGet('LabourMaster', data);
  addLabourMaster = (data: any) => this.commonService.doPost('LabourMaster', data);
  // updateLabourMaster = (data: any) => this.commonService.doPost('LabourMaster' + '/' + data.head.CmpCode + '/' + data.head.ID, data);
  deleteSubLabour = (data: any) => this.commonService.doDelete('LabourMaster' + '/' + 'LabourDetails', data);

  deleteLabourMaster = (data: any) => this.commonService.doDelete('LabourMaster' + '/' + data.head.CmpCode + '/' + data.head.ID, data);

  getMaterialMaster = (data: any) => this.commonService.doGet('MaterialMaster', data);
  getMaterialMasterById = (data: any) => this.commonService.doGet('MaterialMaster' + '/' + data.head.CmpCode, data);
  getMateriaSubmateria = (data: any) => this.commonService.doGet('MaterialMaster' + '/' + data.head.materialID + '/' + data.head.materialSubID + '/' + data.head.materialDetailID, data);//
  addMaterialMaster = (data: any) => this.commonService.doPost('MaterialMaster', data);
  updateMaterialMaster = (data: any) => this.commonService.doPut('MaterialMaster' + '/' + data.head.CmpCode + '/' + data.head.ID, data);
  deleteDetailsMaterialSub = (data: any) => this.commonService.doDelete('MaterialMaster' + '/' + 'MaterialSub', data);
  deleteActualMaterial = (data: any) => this.commonService.doDelete('MaterialMaster' + '/' + 'materialDetails', data);
  // Common For material,Labour, Apply Supply Masters
  deleteMaster = (data: any) => this.commonService.doDelete(data.URI, data);

  getApplySupply = (data: any) => this.commonService.doGet('ApplySupply', data);
  getApplySupplyById = (data: any) => this.commonService.doGet('ApplySupply' + '/' + data.head.CmpCode, data);
  addApplySupply = (data: any) => this.commonService.doPost('ApplySupply', data);
  updateApplySupply = (data: any) => this.commonService.doPut('ApplySupply' + '/' + data.head.CmpCode + '/' + data.head.ID, data);
  deleteApplySupply = (data: any) => this.commonService.doDelete('ApplySupply' + '/' + data.head.CmpCode + '/' + data.head.ID, data);
  deleteSubApplySupply = (data: any) => this.commonService.doDelete('ApplySupply' + '/' + 'ApplySupplyDetails', data);

  addMaterialRates = (data: any) => this.commonService.doPost('MaterialRates', data);
  updateMaterialRates = (data: any) => this.commonService.doPut('MaterialRates' + '/' + data.head.CmpCode + '/' + data.head.ID, data);
  deleteMaterialRates = (data: any) => this.commonService.doDelete('MaterialRates' + '/' + data.head.CmpCode + '/' + data.head.ID, data);

  addLabourRates = (data: any) => this.commonService.doPost('LabourRates', data);
  updateLabourRates = (data: any) => this.commonService.doPut('LabourRates' + '/' + data.head.CmpCode + '/' + data.head.ID, data);
  deleteLabourRates = (data: any) => this.commonService.doDelete('LabourRates' + '/' + data.head.CmpCode + '/' + data.head.ID, data);

  addApplySupplyRates = (data: any) => this.commonService.doPost('ApplySupplyRates', data);
  updateApplySupplyRates = (data: any) => this.commonService.doPut('ApplySupplyRates' + '/' + data.head.CmpCode + '/' + data.head.ID, data);
  deleteApplySupplyRates = (data: any) => this.commonService.doDelete('ApplySupplyRates' + '/' + data.head.CmpCode + '/' + data.head.ID, data);
  // Common Get Method For Material, Labour and Apply Supply Rate
  getRateMasters = (data: any) => this.commonService.doGet(data.URI, data);

  getRateAnalysis = (data: any) => this.commonService.doGet('RateAnalysis', data);
  getCurrentBOQ  = (data:any) => this.commonService.doGet('BOQ/Current' ,data);
  addRateAnalysis = (data: any) => this.commonService.doPost('RateAnalysis', data);
  updateRateAnalysis = (data: any) => this.commonService.doPut('RateAnalysis' + "/" + data.head.raid, data);
  deleteRA = (data: any) => this.commonService.doDelete('RateAnalysis' + "/" + data.head.raid, data);

  sendToApproval = (data: any) => this.commonService.doPut('RateAnalysis/Approved' + '/' + data.head.raid +'/' + data.head.Movement, data);
  deleteRateAnalysis = (data: any) => this.commonService.doDelete('RateAnalysis' + '/' + 'DelPSE', data);
  deleteRateAnalysisHesdSeq = (data: any) => this.commonService.doDelete('RateAnalysis' + "/" + data.head.CmpCode + "/" + data.head.plantCode + "/" + data.head.projCode + "/" + data.head.actGroupId + "/" + data.head.actSeqNo, data);

  getIDCAnalysis = (data: any) => this.commonService.doGet('IDCAnalysis', data);
  getIDCAnalysisById = (data: any) => this.commonService.doGet('IDCAnalysis' + "/" + data.head.CmpCode + "/" + data.head.plantCode, data);
  addIDCAnalysis = (data: any) => this.commonService.doPost('IDCAnalysis', data);
  updateIDCAnalysis = (data: any) => this.commonService.doPut('IDCAnalysis' + '/' + data.head.CmpCode + '/' + data.head.plantCode + '/' + data.head.projCode, data);
  sendToApprovalIDC = (data: any) => this.commonService.doPut('IDCAnalysis' + '/' + 'sendToApproval' + "/" + data.head.CmpCode + "/" + data.head.plantCode + "/" + data.head.projCode, data);

  getMiscellaneousRates = (data: any) => this.commonService.doGet('MiscellaneousRates' + "/" + "/" + data.head.CmpCode + "/" + data.head.plantCode + "/" + data.head.projCode, data);
  addMiscellaneousRates = (data: any) => this.commonService.doPost('MiscellaneousRates', data);
  deleteMiscellaneousRates = (data: any) => this.commonService.doDelete('MiscellaneousRates', data);
  deleteMiscellaneousRatesHesdSeq = (data: any) => this.commonService.doDelete('MiscellaneousRates' + "/" + data.head.CmpCode + "/" + data.head.plantCode + "/" + data.head.projCode, data);

  getIDCActivity = (data: any) => this.commonService.doGet('IDCActivity', data);
  getIDCActivityById = (data: any) => this.commonService.doGet('IDCActivity' + '/' + data.head.CmpCode, data);
  addIDCActivity = (data: any) => this.commonService.doPost('IDCActivity', data);
  updateIDCActivity = (data: any) => this.commonService.doPut('IDCActivity' + '/' + data.head.CmpCode + '/' + data.head.ID, data);

  getVmmMaster = (data: any) => this.commonService.doGet('VmmMaster', data);
  getVmmMasterID = (data: any) => this.commonService.doGet('VmmMaster' + '/' + data.head.CmpCode, data);
  // getLabourMasterById = (data: any) => this.commonService.doGet('VmmMaster', data);
  addVmmMaster = (data: any) => this.commonService.doPost('VmmMaster', data);
  // updateLabourMaster = (data: any) => this.commonService.doPost('VmmMaster' + '/' + data.head.CmpCode + '/' + data.head.ID, data);
  deleteVmmMaster = (data: any) => this.commonService.doDelete('VmmMaster' + '/' + data.head.CmpCode + '/' + data.head.ID, data);

  getmmmMaster = (data: any) => this.commonService.doGet('MmmMaster', data);
  getmmmMasterID = (data: any) => this.commonService.doGet('MmmMaster' + '/' + data.head.CmpCode, data);
  addmmmMaster = (data: any) => this.commonService.doPost('MmmMaster', data);
  deletemmmMaster = (data: any) => this.commonService.doDelete('MmmMaster' + '/' + data.head.CmpCode + '/' + data.head.ID, data);

  getMiscellaneous = (data: any) => this.commonService.doGet('Miscellaneous', data);
  addMiscellaneous = (data: any) => this.commonService.doPost('Miscellaneous', data);
  deleteMiscellaneous = (data: any) => this.commonService.doDelete('Miscellaneous' + '/' + data.head.CmpCode + '/' + data.head.ID, data);

  //remove this api
  getIDCMaster = (data: any) => this.commonService.doGet('IDCActivity', data);
  getIDCMasterById = (data: any) => this.commonService.doGet('IDCActivity' + '/' + data.head.CmpCode, data);
  // getMateriaSubmateria = (data: any) => this.commonService.doGet('IDCActivity' + '/' + data.head.materialID + '/' + data.head.materialSubID + '/' + data.head.materialDetailID, data);//
  addIDCMaster = (data: any) => this.commonService.doPost('IDCActivity', data);
  updateIDCMasterById = (data: any) => this.commonService.doPut('IDCActivity' + '/' + data.head.CmpCode + '/' + data.head.ID, data);
  deleteDetailsIDCSub = (data: any) => this.commonService.doDelete('IDCActivity' + '/' + 'IDCActivitySub', data);
  deleteActualIDC = (data: any) => this.commonService.doDelete('IDCActivity' + '/' + 'IDCActivityDetails', data);

  getSOMLA = (data: any) => this.commonService.doGet('SOMLA', data);

  getVendor = (data: any) => this.commonService.doGet('Vendor', data);
  getVendorCategotyList = (data: any) => this.commonService.doGet('Vendor', data);
  getVendorCategoty= (data: any) => this.commonService.doGet('vendorCategory', data);
  addVendor = (data: any) => this.commonService.doPost('Vendor', data);
  updateVendor = (data: any) => this.commonService.doPut('Vendor' +'/' + data.head.bpCode, data);
  deleteVendor = (data: any) => this.commonService.doDelete('Vendor'+ '/' + data.head.bpCode, data);


  getServiceRequestNote = (data: any) => this.commonService.doGet('ServiceRequestNote', data);
  getSRN = (data: any) => this.commonService.doGet('ServiceRequestNote'+ '/' + data.head.SRN + '/'+ data.head.CmpCode + '/' + data.head.ProjCode , data);
  addServiceRequestNote = (data: any) => this.commonService.doPost('ServiceRequestNote', data);
  updateServiceRequestNote = (data: any) => this.commonService.doPut('ServiceRequestNote' + '/' + data.head.SRN, data);
  updateForApproval = (data: any) => this.commonService.doPut('ServiceRequestNote' + '/' + 'Approved' + '/' + data.head.SRN + '/' + data.head.Movement, data);
  deleteServiceRequestNote = (data: any) => this.commonService.doDelete('ServiceRequestNote' + '/' + data.head.SRN, data)


  getWordOrder = (data: any) => this.commonService.doGet('WorkOrder', data);
  getInWordOrder = (data: any) => this.commonService.doGet('WorkOrder' + '/' + data.head.workOrderID, data);
  addWordOrder = (data: any) => this.commonService.doPost('WorkOrder', data);
  updateWordOrder = (data: any) => this.commonService.doPut('WorkOrder' + '/' + data.head.workOrderID, data);
  ForApprovalWordOrder = (data: any) => this.commonService.doPut('WorkOrder' + '/' + 'Approved' + '/'  + data.head.workOrderID +'/'+ data.head.SRN + '/' + data.head.Movement, data);
  updateApprovaWorkOrder = (data: any) => this.commonService.doPut('WorkOrder' + '/' + 'Approved' + '/' + data.head.WorkOrderID + '/'+ data.head.CmpCode + '/' + data.head.plantCode + '/' + data.head.projCode + '/' + data.head.Movement, data);
  deleteWordOrder = (data: any) => this.commonService.doDelete('WorkOrder' + '/' + data.head.workOrderID + '/' + data.head.srn, data);

  getvendortoWordOrder = (data: any) => this.commonService.doGet('Vendor' + '/' + 'WorkOrderVenders' + '/' + data.head.CmpCode + '/' + data.head.plantCode + '/' + data.head.projCode, data);

  getWordOrderListApprove = (data: any) => this.commonService.doGet('Vendor/VendoeWorkOrder/' + data.head.CmpCode + '/' + data.head.plantCode + '/' + data.head.projCode, data);

  getWorkOrderLab = (data: any) => this.commonService.doGet('WorkOrder' + '/' + data.head.workOrderID, data);


  getDDS = (data: any) => this.commonService.doGet('LabourDDS', data);
  addLabourDDS = (data: any) => this.commonService.doPost('LabourDDS', data);
  updateLabourDDS = (data: any) => this.commonService.doPut('LabourDDS' + '/' + data.head.lddsID, data);
  deleteLabourDDS = (data: any) => this.commonService.doDelete('LabourDDS' + '/' + data.head.lddsID, data)

  getLabourBills = (data: any) => this.commonService.doGet('LabourBills', data);
  addLabourBills = (data: any) => this.commonService.doPost('LabourBills', data);
  updateLabourBills = (data: any) => this.commonService.doPut('LabourBills' + '/' + data.head.billId, data);
  deleteLabourBills = (data: any) => this.commonService.doDelete('LabourBills' + '/' + data.head.billId, data)

  getPreviousBillDate = (data: any) => this.commonService.doGet('LabourBills/PreviousBillingDate/' + data.head.CmpCode + '/' + data.head.plantCode + '/' + data.head.projCode + '/' + data.head.Vendor, data);

  getDDSFromTodate = (data: any) => this.commonService.doGet('LabourDDS' + '/' + data.head.FromDate + '/' + data.head.ToDate, data)

  getboqHeadClientBill = (data: any) => this.commonService.doGet('BOQ/GetBOQHead/' + data.head.CmpCode + '/' + data.head.plantCode + '/' + data.head.projCode, data);

  getBoqDetailsHead = (data: any) => this.commonService.doGet('ClientBill/GetBOQHeadDetails/' + data.head.CmpCode + '/' + data.head.plantCode + '/' + data.head.projCode, data);

  addClientBill = (data: any) => this.commonService.doPost('ClientBill', data);

  PutClientBill = (data: any) => this.commonService.doPut('ClientBill' + '/' + data.head.id, data);

  getClientBillCertify = (data: any) => this.commonService.doGet('ClientBill/' + data.head.CmpCode + '/' + data.head.plantCode + '/' + data.head.projCode, data);

  deleteClientBill = (data: any) => this.commonService.doDelete('ClientBill' + '/' + data.head.ID, data);

  getActivityGroup = (data: any) => this.commonService.doGet('activitytype' + '/' + data.head.CmpCode, data);
  getActivityGroupHead = (data: any) => this.commonService.doGet('ActivityHead' + '/' + data.head.CmpCode, data);
  addActivityHead = (data: any) => this.commonService.doPost('ActivityHead', data);
  deleteActivityHead = (data: any) => this.commonService.doDelete('ActivityHead' + '/' + data.head.CmpCode + '/' + data.head.ActGroupId, data);
  updateActivityHead = (data: any) => this.commonService.doPut('ActivityHead' + '/' + data.head.CmpCode + '/' + data.head.ActGroupId, data);

  updateRateMaterial = (data: any) => this.commonService.doPut('MaterialRates/', data);
  deleteRateMaterial = (data: any) => this.commonService.doDelete('MaterialRates/', data);

  updateRateLabour = (data: any) => this.commonService.doPut('LabourRates/', data);
  deleteRateLabour = (data: any) => this.commonService.doDelete('LabourRates/', data);

  updateRateApplySupply = (data: any) => this.commonService.doPut('ApplySupplyRates/', data);
  deleteRateApplySupply = (data: any) => this.commonService.doDelete('ApplySupplyRates/',data);

  getRateMaterial = (data: any) => this.commonService.doGet('MaterialRates/' + data.head.CmpCode + "/" + data.head.plantCode + "/" + data.head.projCode, data);
  getRateLabour = (data: any) => this.commonService.doGet('LabourRates/' + data.head.CmpCode + "/" + data.head.plantCode + "/" + data.head.projCode, data);
  getRateApplySupply = (data: any) => this.commonService.doGet('ApplySupplyRates/' + data.head.CmpCode + "/" + data.head.plantCode + "/" + data.head.projCode, data);

  getReconciliation = (data: any) => this.commonService.doGet('Reconciliation/' + data.head.CmpCode + "/" + data.head.plantCode + "/" + data.head.projCode, data);

  ResultExport = (data: any) => this.commonService.doGetBlob('Reconciliation/' + data.head.CmpCode + "/" + data.head.plantCode + "/" + data.head.projCode, data);

  getAccess = (data: any) => this.commonService.doGet('Access', data);
  addRole = (data: any) => this.commonService.doPost('Role', data);
  getRole = (data: any) => this.commonService.doGet('Role' + '/' + data.head.CmpCode, data);
  deleteRole = (data: any) => this.commonService.doDelete('Role' + '/' + data.head.roleID, data);
  updateRole = (data: any) => this.commonService.doPut('Role' + '/' + data.head.roleID, data)


  addUsers = (data: any) => this.commonService.doPost('Users', data);
  getUsers = (data: any) => this.commonService.doGet('Users' + '/' + data.head.CmpCode, data);
  getProjectUsers = (data:any) => this.commonService.doGet('project/ProjectName',data)
  deleteUsers = (data: any) => this.commonService.doDelete('Users' + '/' + data.head.UserId + '/' + data.head.CmpCode, data);
  updateUsers = (data: any) => this.commonService.doPut('Users' + '/' + data.head.UserId, data);

  getLoginUser = (data: any) => this.commonService.doGet('Users/Login', data);

  getSchedule = (data: any) => this.commonService.doGet('Schedule' , data);
  addSchedule = (data: any) => this.commonService.doPost('Schedule', data)


  getMRN = (data: any) => this.commonService.doGet('MaterialPurchesNote' + '/' + data.head.CmpCode, data);
  getMRNEdit = (data: any) => this.commonService.doGet('MaterialPurchesNote' + '/' + data.head.mrnid + '/' + data.head.CmpCode + '/' + data.head.plantCode + '/' + data.head.projCode, data);
  addMRN = (data: any) => this.commonService.doPost('MaterialPurchesNote', data);
  updateMRN = (data: any) => this.commonService.doPut('MaterialPurchesNote' + '/' + data.head.CmpCode + '/' + data.head.bpCode, data);
  deleteMRN = (data: any) => this.commonService.doDelete('MaterialPurchesNote' + '/' + data.head.mrnid + '/' + data.head.CmpCode + '/' + data.head.plantCode + '/' + data.head.projCode, data);

  //MRN send to Approvar
  MRNSendForApproval = (data: any) => this.commonService.doPut('MaterialPurchesNote/Approve/' + data.head.mrnid + '/' + data.head.CmpCode + '/' + 0, data);
  //MRN Get Approvar
  getMRNApproval = (data: any) => this.commonService.doGet('MaterialPurchesNote' + '/' + data.head.CmpCode, data);
  //Approve and DissApprove MRN
  MRNApprAndDis = (data: any) => this.commonService.doPut('MaterialPurchesNote/Approve/' + data.head.mrnid + '/' + data.head.CmpCode + '/' + 1, data);
  //Quotation Template
  getQuotationTemplate = (data: any) => this.commonService.doGet('QuotationParametersTemplate' + '/' + data.head.CmpCode, data);
  addQuotationTemplate = (data: any) => this.commonService.doPost('QuotationParametersTemplate', data);
  updateQuotationTemplate = (data: any) => this.commonService.doPut('QuotationParametersTemplate' + '/' + data.head.qpTemplateID + '/' + data.head.CmpCode, data);
  deleteQuotationTemplate = (data: any) => this.commonService.doDelete('QuotationParametersTemplate' + '/' + data.head.qpTemplateID + '/' + data.head.CmpCode, data);

  getNotification = (data:any)=> this.commonService.doGet('Notification'+ '/' + data.head.CmpCode + '/' + data.head.userid,data)
  updateNotification = (data:any) => this.commonService.doPut('Notification' + '/' + data.head.id,data)

  //Email Template
  getEmailTemplate = (data: any) => this.commonService.doGet('EmailTemplate' + '/' + data.head.CmpCode, data);
  addEmailTemplate = (data: any) => this.commonService.doPost('EmailTemplate', data);
  updateEmailTemplate = (data: any) => this.commonService.doPut('EmailTemplate' + '/' + data.head.id+ '/' + data.head.CmpCode , data);
  deleteEmailTemplate = (data: any) => this.commonService.doDelete('EmailTemplate' + '/' + data.head.id+ '/' + data.head.CmpCode , data);

  
  getPlaceholder = (data: any) => this.commonService.doGet('Placeholder' + '/' + data.head.CmpCode, data);

  updateLabourRatesApproved = (data: any) => this.commonService.doPut('LabourRates/Approved' + '/' + data.head.Movement, data)
  updateApplySupplyRatesApproved = (data: any) => this.commonService.doPut('ApplySupplyRates/Approved' + '/' + data.head.Movement, data)
  updateMaterialApproved = (data: any) => this.commonService.doPut('MaterialRates/Approved' +'/' + data.head.Movement, data)

  
  getQuotationRequest =  (data: any) => this.commonService.doGet('Quotation' + '/' + data.head.CmpCode, data);
  postQuotationRequest =  (data: any) => this.commonService.doPost('Quotation', data);
  updateQuotationRequest =  (data: any) => this.commonService.doPut('Quotation' + '/' + data.head.quotationID + '/' + data.head.CmpCode, data);
  deleteQuotationRequest =  (data: any) => this.commonService.doDelete('Quotation' +'/' + data.head.quotationID +'/' + data.head.CmpCode, data);
  
  getQuotationRequests =  (data: any) => this.commonService.doGet('Quotation' , data);
  postQuotationRequests =  (data: any) => this.commonService.doPost('Quotation', data);
  updateQuotationRequests =  (data: any) => this.commonService.doPut('Quotation' + '/' + data.head.quotationID + '/' + data.head.CmpCode, data);
  deleteQuotationRequests =  (data: any) => this.commonService.doDelete('Quotation' +'/' + data.head.quotationID +'/' + data.head.CmpCode, data);

  getVendorCategory = (data: any) => this.commonService.doGet('VendorCategory' , data);
  postVendorCategory = (data: any) => this.commonService.doPost('VendorCategory' , data);
  updateVendorCategory =  (data: any) => this.commonService.doPut('VendorCategory'+'/' + data.head.Category , data);
  deleteVendorCategory =  (data: any) => this.commonService.doDelete('VendorCategory'+'/' + data.head.Category, data);

  getSac = (data: any) => this.commonService.doGet('SAC' + '/' + data.head.CountryCode , data);
  addSac = (data: any) => this.commonService.doPost('SAC', data);
  updateSac =  (data: any) => this.commonService.doPut('SAC'+ '/' + data.head.CountryCode , data);
  // deleteSac =  (data: any) => this.commonService.doDelete('SAC' + '/' + data.head.CountryCode, data);

  deleteSac = (data: any) => this.commonService.doDelete(data.URI  + '/' + data.head.hsnSacCode + '/' + data.head.CountryCode + '/' + data.head.StateCode, data); 
 

   getHsn = (data: any) => this.commonService.doGet('HSN' + '/' + data.head.CountryCode, data);
   addHsn = (data: any) => this.commonService.doPost('HSN', data);
  updateHsn =  (data: any) => this.commonService.doPut('HSN' + '/' + data.head.CountryCode, data);
  // deleteHsn =  (data: any) => this.commonService.doDelete('HSN' + '/' + data.head.CountryCode, data);hsnSacCode
  deleteHsn = (data: any) => this.commonService.doDelete(data.URI  + '/' + data.head.hsnSacCode + '/' + data.head.CountryCode + '/' + data.head.StateCode, data); 
 
  getSRNRequestNote = (data: any) => this.commonService.doGet('ServiceRequestNote/GetSRN' + '/' + data.head.CmpCode + '/' + data.head.ProjCode, data);

  getSRNAnalysisVie = (data: any) => this.commonService.doGet('ServiceRequestNote/GetSRNAnalysis' + '/' + data.head.CmpCode + '/' + data.head.ProjCode + '/' + data.head.Srnno, data); 
  getSRNDetailsA = (data: any) => this.commonService.doGet('ServiceRequestNote/GetSRNContaint' + '/' + data.head.CmpCode + '/' + data.head.ProjCode + '/' + data.head.Srnno, data); 
 
}

