export const environment = {
  production: true,
  // baseUrl:'http://115.160.216.115:4004/API/',

  // baseUrl:'http://115.160.216.115:1080/API/',

  // baseUrl:'https://96wktxd7-5001.inc1.devtunnels.ms/API/',
  baseUrl:'https://sconapi.primustechsys.com/api/',
  Cid:"1000",
  Pid:"1010",
  updateBy:"101770",
  createBy:"101770"
};