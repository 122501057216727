
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../services/common.service';
import { ApiCallService } from '../services/api-call.service';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { IpServiceService } from '../services/ip-service.service'; 
import { HttpClient  } from '@angular/common/http'; 
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  hide = true;
  loginForm: FormGroup;
  submitted: any;
  ipAddress:any; 

  api_key = 'YOUR API KEY';
  url = 'https://ipgeolocation.abstractapi.com/v1/?api_key=' + this.api_key;

  constructor(private http:HttpClient,private ip:IpServiceService,public fb: FormBuilder, private router: Router, private commonService: CommonService, private apiService: ApiCallService) {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required]],
      password: ['', [Validators.required]],
      // ip:['', [Validators.required]],
    })
  }
  ngOnInit() {
    document.body.style.backgroundColor = "#E5E7E9";
    // this.getIP();  

    // this.http.get(this.url).subscribe((res:any)=>{
    //   this.ipAddress = res.data.ip_address;
    // });

    // console.log('this.ipAddress',this.ipAddress)
  }   
// getIP()  
// {  
//   this.ip.getIPAddress().subscribe((res:any)=>{  
//     this.ipAddress=res.ip;  
//   });  
// }

  ngOnDestroy() {
    document.body.style.background = '#fff';
  }

  submitForm() {
    if (this.loginForm.invalid) {
      this.submitted = true;
      this.loginForm.markAllAsTouched()
      return;
    }
    let head = { CmpCode: '', PlantCode: '', ProjCode: '', UserId: '' }
    let datObj = { head: head,param: { "userID": this.loginForm.value.email, "password": this.loginForm.value.password }}   
    this.apiService.getLoginUser(datObj).subscribe({
      next: async (result: any) => {
        if (result) {
          this.commonService.showSuccess('Logged In Successfully !!');
          this.router.navigate(['/components']);
          sessionStorage.setItem('token', "Dummy_Token");
          sessionStorage.setItem('key', window.btoa(JSON.stringify(result)));
          // window.localStorage.setItem('key',   window.btoa(JSON.stringify(result)));
        }
      }, error: (error: any) => {
        this.commonService.showError(error);
      }
    })
  }
}








// if (this.loginForm.valid) {
//   // this.commonService.callToster('Please provide all the required values!')
//   return false;
// } else {
//   console.log(this.loginForm.value);
//   //crete the data object to send to the middleware api
//   var dataobj = { data: null, param: { "userID": this.loginForm.value.email, "password": this.loginForm.value.password } };

//   //call the middleware login api
//   this.apiService.getLoginUser(dataobj).subscribe({
//     next: (res: any) => {
//       console.log("res is hrer", res);
//       //check if user status is active i.e 1
      
//       this.commonService.showSuccess('LogedIn Successfully !!');
// this.router.navigate(['components']);


//     },
//     error: (err: any) => {
//       //catch all errors
//       console.error(err);
//       // this.commonService.callToster('Invalid User!')
//     },
//   })
// }



 //   if (this.loginForm.valid) {
  //     console.log("form submitted details", this.loginForm.value);
  //    if(this.loginForm.value.email == 'primus@abc.com' && this.loginForm.value.password=='primus@123')
  //    {
  //     this.commonService.showSuccess('LogedIn Successfully !!');
  //     this.router.navigate(['components']);
  //    }else
  //    {
  //     this.commonService.showError('Incorrect Credentials !!')
  //    }
  //   }
  //   else {
  //     this.commonService.showError('Fill out all the details')
  //   }

  // }

