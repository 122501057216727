import { Injectable } from '@angular/core'
import { Observable } from 'rxjs';
import * as _ from 'lodash';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
@Injectable()
export class XlsxToJsonService {
    /**
     * This is use to conver excel sheet into json object
     */
    public processFileToJson(object: any, file: any): Observable<any> {
        //  console.log("Json file : ", object, file)
        const reader = new FileReader();
        const _this = this;
        // console.log("Json reader ,_this: ", reader)
        return Observable.create((observer: any) => {
            reader.onload = function (e: any) {
                const data = e.target['result'];

                const workbook = XLSX.read(data, {
                    type: 'binary'
                });

                object.sheets = _this.parseWorksheet(workbook, true, true);
                observer.next(object);
                observer.complete();
            }
            //console.log("Json Reader : ",reader.readAsBinaryString(file))
            reader.readAsBinaryString(file);

        });
    }


    parseWorksheet(workbook: any, readCells: any, toJSON: any) {
        if (toJSON === true) {
            return this.to_json(workbook);
        }
        let sheets: any = {};
        _.forEachRight(workbook.SheetNames, function (sheetName: any) {
            let sheet = workbook.Sheets[sheetName];
            sheets[sheetName] = XlsxToJsonService.parseSheet(sheet, readCells);
        });
        return sheets;
    }

    static parseSheet(sheet: any, readCells: any) {
        let range = XLSX.utils.decode_range(sheet['!ref']);
        let sheetData: any = [];

        if (readCells === true) {
            _.forEachRight(_.range(range.s.r, range.e.r + 1), function (row: any) {
                let rowData: any = [];
                _.forEachRight(_.range(range.s.c, range.e.c + 1), function (column: any) {
                    let cellIndex = XLSX.utils.encode_cell({
                        'c': column,
                        'r': row
                    });
                    let cell = sheet[cellIndex];
                    rowData[column] = cell ? cell.v : undefined;
                });
                sheetData[row] = rowData;
            });
        }

        return {
            'sheet': sheetData,
            'name': sheet.name,
            'col_size': range.e.c + 1,
            'row_size': range.e.r + 1
        }
    }

    to_json(workbook: any) {
        const result: any = {};
        workbook.SheetNames.forEach(function (sheetName: any) {
            const roa = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
            if (roa.length > 0) {
                result[sheetName] = roa;
            }
        });
        return result;
    }

    /**
   * This is use to conver json object into excel sheet
   */

    public exportAsExcelFile(json: any[], excelFileName: string): void {

        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
        console.log('worksheet', worksheet);
        const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
        this.saveAsExcelFile(excelBuffer, excelFileName);
    }

    private saveAsExcelFile(buffer: any, fileName: string): void {
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    }



}