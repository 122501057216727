import { Injectable } from '@angular/core';
import { Workbook } from 'exceljs';
import * as FileSaver from 'file-saver';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
import * as XLSX from 'xlsx';


@Injectable({
  providedIn: 'root'
})

export class JsonToXlsxService {
  arr: any;
  d: any;
  processedData: any;
  translate: any;
  footerdata: any;
  total: any;
  constructor() { }

  // npm exceljs and FileSaver.
  public exportExcel(json: any[], headersArray: any, JSONList: any, excelFileName: string): void {
    {
      console.log("json >>", json)
      //Excel Title, Header, Data.
      const company = JSONList[0].company;
      const subheader = JSONList[1].subheader;
      const subtitle = JSONList[2].subtitle;
      const data = JSON.parse(JSON.stringify(json));
      console.log("data >>", data)
      var maxcol = 1;
      for (let x in data[0]) { maxcol++ }


      //Create workbook and worksheet.
      let workbook = new Workbook();
      let worksheet = workbook.addWorksheet(excelFileName);


      //Create getCell and mergeCells for headers.
      let cell = worksheet.getCell(1, 1);
      cell.value = company;
      cell.style = { alignment: { horizontal: 'center', vertical: 'middle' } }
      worksheet.mergeCells(1, 1, 1, maxcol);
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
      cell.font = { name: 'Arial', family: 2, size: 10, bold: true };

      cell = worksheet.getCell(2, 1);
      cell.value = subheader;
      cell.style = { alignment: { horizontal: 'center', vertical: 'middle' } }
      worksheet.mergeCells(2, 1, 2, maxcol);
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
      cell.font = { name: 'Arial', family: 1, size: 10, bold: true };

      cell = worksheet.getCell(3, 1);
      cell.value = subtitle;
      cell.style = { alignment: { horizontal: 'center', vertical: 'middle' } }
      worksheet.mergeCells(3, 1, 3, maxcol);
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };


      //Create header style.
      var i = 1;
      var arr: any = []
      headersArray.forEach((element: any) => {
        cell = worksheet.getCell(4, i);
        cell.value = element;
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'ffdbe0e0' },
          bgColor: { argb: 'ffdbe0e0' }
        }
        cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
        cell.font = { name: 'Arial', family: 1, size: 10, bold: true };
        arr[i] = element.length + 2;
        i++;
      });
      var row = 5;


      //Create JSON iteration.
      data.forEach((element: any) => {

        cell = worksheet.getCell(row, 1);
        cell.value = row - 4;
        cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
        arr[1] = 9;

        var i = 2;
        for (let x in element) {
          cell = worksheet.getCell(row, i);
          cell.value = element[x];
          cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
          let y = element[x] == undefined ? 0 : element[x].length;
          if (arr[i] < y) arr[i] = y + 2;
          i++;
        }
        row++;
      })

      i = 1;
      worksheet.columns.forEach(function (column) {
        column.width = arr[i++];
      });

      if (JSONList.length > 3) { if (JSONList[3].delete == 3) { worksheet.spliceRows(1, 3); } }
      //Generate Excel File with given name.
      workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], { type: EXCEL_TYPE });
        var d = new Date, dformat = [d.getDate(), d.getMonth() + 1, d.getFullYear()].join('/') + ' ' + [d.getHours(), d.getMinutes(), d.getSeconds()].join(':');
        FileSaver.saveAs(blob, excelFileName + '_export_' + dformat + EXCEL_EXTENSION);
      })

    }
  }


  // This Function only use for Single Barcode
  public exportBarcodeExcel(json: any[], headersArray: any, JSONList: any, excelFileName: string): void {
    {

      //Excel Title, Header, Data.
      const company = JSONList[0].company;
      const subheader = JSONList[1].subheader;
      const subtitle = JSONList[2].subtitle;
      const data = JSON.parse(JSON.stringify(json));
      var maxcol = 1;
      for (let x in data[0]) { maxcol++ }


      //Create workbook and worksheet.
      let workbook = new Workbook();
      let worksheet = workbook.addWorksheet(excelFileName);


      //Create getCell and mergeCells for headers.
      let cell = worksheet.getCell(1, 1);
      cell.value = company;
      cell.style = { alignment: { horizontal: 'center', vertical: 'middle' } }
      worksheet.mergeCells(1, 1, 1, maxcol);
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
      cell.font = { name: 'Arial', family: 2, size: 10, bold: true };

      cell = worksheet.getCell(2, 1);
      cell.value = subheader;
      cell.style = { alignment: { horizontal: 'center', vertical: 'middle' } }
      worksheet.mergeCells(2, 1, 2, maxcol);
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
      cell.font = { name: 'Arial', family: 1, size: 10, bold: true };

      cell = worksheet.getCell(3, 1);
      cell.value = subtitle;
      cell.style = { alignment: { horizontal: 'center', vertical: 'middle' } }
      worksheet.mergeCells(3, 1, 3, maxcol);
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

      //Create header style.
      var i = 1;
      var arr: any = []
      headersArray.forEach((element: any) => {
        cell = worksheet.getCell(4, i);
        cell.value = element;
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFFFFF00' },
          bgColor: { argb: 'aa0000aa' }
        }
        cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
        cell.font = { name: 'Arial', family: 1, size: 10, bold: true };
        arr[i] = element.length + 2;
        i++;
      });
      var row = 5;


      //Create JSON iteration.
      data.forEach((element: any) => {

        cell = worksheet.getCell(row, 1);
        cell.value = row - 4;
        cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
        arr[1] = 9;

        var i = 2;
        for (let x in element) {
          cell = worksheet.getCell(row, i);
          cell.value = element[x];
          cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
          let y = element[x] == undefined ? 0 : element[x].length;
          if (arr[i] < y) arr[i] = y + 2;
          i++;
        }
        row++;
      })

      i = 1;
      worksheet.columns.forEach(function (column) {
        column.width = arr[i++];
      });
      var X = json.length - 1;
      worksheet.mergeCells(5, 1, 5 + X, 1);
      let cell1 = worksheet.getCell(5, 1);
      cell1.style = { alignment: { horizontal: 'center', vertical: 'middle' } }

      //Generate Excel File with given name.
      workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], { type: EXCEL_TYPE });
        FileSaver.saveAs(blob, excelFileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
      })

    }
  }


  // npm exceljs and FileSaver.
  public exportExcelWithoutHead(json: any[], ColumeName: any, excelFileName: string): void {
    {
      console.log("json >>", json)
      //Excel Title, Header, Data.

      const data = JSON.parse(JSON.stringify(json));
      console.log("data >>", data)
      // var maxcol = 7;
      // for (let x in data[0]) { maxcol++ }


      //Create workbook and worksheet.
      let workbook = new Workbook();
      let worksheet = workbook.addWorksheet(excelFileName);

      //Create getCell and mergeCells for headers.
      let cell = worksheet.getCell(1, 1);

      //Create header style.
      var i = 1;
      var arr: any = []
      ColumeName.forEach((element: any) => {
        cell = worksheet.getCell(1, i);
        cell.value = element;
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'ffdbe0e0' },
          bgColor: { argb: 'ffdbe0e0' }
        }
        cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
        cell.font = { name: 'Arial', family: 1, size: 10, bold: true };
        arr[i] = element.length + 2;
        i++;
      });
      var row = 2;


      //Create JSON iteration.
      data.forEach((element: any) => {

        //   cell = worksheet.getCell(row, 1);
        //   cell.value = row - 1;
        //   cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
        //   arr[1] = 9;

        var i = 1;
        for (let x in element) {
          cell = worksheet.getCell(row, i);
          cell.value = element[x];
          cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
          let y = element[x] == undefined ? 0 : element[x].length;
          if (arr[i] < y) arr[i] = y + 2;
          i++;
        }
        row++;
      })

      i = 1;
      worksheet.columns.forEach(function (column) {
        column.width = arr[i++];
      });

      // if (JSONList.length>3){ if (JSONList[3].delete == 3) { worksheet.spliceRows(1, 3); }}
      //Generate Excel File with given name.
      workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], { type: EXCEL_TYPE });
        var d = new Date, dformat = [d.getDate(), d.getMonth() + 1, d.getFullYear()].join('/') + ' ' + [d.getHours(), d.getMinutes(), d.getSeconds()].join(':');
        FileSaver.saveAs(blob, excelFileName + '_export_' + dformat + EXCEL_EXTENSION);
      })

    }
  }

  public exportExcelWithoutHeadRA(json: any[], headers: any, add: any, ColumeName: any, excelFileName: string): void {
    {
      console.log("json >>", add)
      //Excel Title, Header, Data.
      const company = add.cmpName;
      const cmpCode = add.cmpCode;
      const projName = add.projName;
      const projCode = add.projCode;
      const plantName = add.plantName;
      const plantCode = add.plantCode;
      const A1 = 'Company Name & Code :'
      const B1 = 'Project Name & Code :'
      const C1 = 'Plant Name & Code :'
      const data = JSON.parse(JSON.stringify(json));
      console.log("data >>", data)
      var maxcol = 1;
      for (let x in data[0]) { maxcol++ }

      //Create workbook and worksheet.
      let workbook = new Workbook();
      let worksheet = workbook.addWorksheet(excelFileName);

      // worksheet.getCell('A1').value = `Company Name & Code: ${company},  ${cmpCode}  `;
      //Create getCell and mergeCells for headers.

      let cell = worksheet.getCell(1, 1);
      cell.value = A1
      cell.style = { alignment: { horizontal: 'left', vertical: 'middle' } }
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
      cell.font = { name: 'Arial', family: 2, size: 10, bold: true };


      cell = worksheet.getCell(1, 2);
      cell.value = company
      cell.style = { alignment: { horizontal: 'left', vertical: 'middle' } }
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
      cell.font = { name: 'Arial', family: 2, size: 10, bold: true };

      cell = worksheet.getCell(1, 3);
      cell.value = cmpCode
      cell.style = { alignment: { horizontal: 'left', vertical: 'middle' } }
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
      cell.font = { name: 'Arial', family: 2, size: 10, bold: true };
     

      cell = worksheet.getCell(2, 1);
      cell.value = B1;
      cell.style = { alignment: { horizontal: 'left', vertical: 'middle' } }
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
      cell.font = { name: 'Arial', family: 2, size: 10, bold: true };

      cell = worksheet.getCell(2, 2);
      cell.value = projName;
      cell.style = { alignment: { horizontal: 'left', vertical: 'middle' } }
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
      cell.font = { name: 'Arial', family: 2, size: 10, bold: true };

      cell = worksheet.getCell(2, 3);
      cell.value = projCode;
      cell.style = { alignment: { horizontal: 'left', vertical: 'middle' } }
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
      cell.font = { name: 'Arial', family: 2, size: 10, bold: true };

      cell = worksheet.getCell(3, 1);
      cell.value = C1;
      cell.style = { alignment: { horizontal: 'left', vertical: 'middle' } }
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
      cell.font = { name: 'Arial', family: 2, size: 10, bold: true };


      cell = worksheet.getCell(3, 2);
      cell.value = plantName;
      cell.style = { alignment: { horizontal: 'left', vertical: 'middle' } }
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
      cell.font = { name: 'Arial', family: 2, size: 10, bold: true };

      cell = worksheet.getCell(3, 3);
      cell.value = plantCode;
      cell.style = { alignment: { horizontal: 'left', vertical: 'middle' } }
      // worksheet.mergeCells(3, 1, 3, maxcol);
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
      cell.font = { name: 'Arial', family: 2, size: 10, bold: true };
      //Create header style.
      var i = 1;
      var arr: any = []
      worksheet.addRow('toi:');
      ColumeName.forEach((element: any) => {
        cell = worksheet.getCell(4, i);
        cell.value = element;
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'ffdbe0e0' },
          bgColor: { argb: 'ffdbe0e0' }
        }
        cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
        cell.font = { name: 'Arial', family: 1, size: 10, bold: true };
        arr[i] = element.length + 2;
        i++;
      });
      var row = 5;

      var arrs: any = []
      //Create JSON iteration.
      for (let j = 0; j < data.length; j++) {
        var i = 1;
        for (let x in data[j]) {
          
          cell = worksheet.getCell(row, i);
          cell.value = data[j][x];
          cell.style = { alignment: { horizontal: 'center', vertical: 'middle' } }
          cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
          let y = data[j][x] == undefined ? 0 : data[j][x].length;

          // console.log('data[x]', data[j], data, i)

          if ([i] < y) arr[i] = y + 2;
          i++;
        }
       
        this.processedData = [];
        var founds = null;
        founds = arrs.find((item: any) => item === data[j].actGroupId)
        console.log("founds", arrs,founds)
        
        if (!founds) {
          // if(founds.length){ worksheet.addRow(["Total222:"])}
         
          arrs.push(data[j].actGroupId)
          var found: any = data.filter((item: any) => item.actGroupId == data[j].actGroupId)
          console.log('(found !',found ,data[j].actGroupId,found.length)

          if (found.length > 1) {
            console.log(row, found.length + row)
            worksheet.mergeCells(row, 1, found.length + row - 1, 1);
            worksheet.mergeCells(row, 2, found.length + row - 1, 2);
            XLSX.utils.sheet_add_aoa(founds, [['ffhfyfhfhf']]);
            // worksheet.addRow('toi:');
            console.log('row', found.length + row - 1)
          }
        }
        row++;

      //   for(let k = 0; k < data[k].length; k++){

      //     console.log('(forrrr!',founds ,data[k].actGroupId,founds.length)
        
      //      founds = arrs.find((item: any) => item === data[k].actGroupId)

      //     worksheet.addRow(["Total222:"])
      //   }
      
      }
      i = 1;
      worksheet.columns.forEach(function (column) {
        column.width = arr[i++];
      });

      if (json.length > 3) { if (json[3].delete == 3) { worksheet.spliceRows(1, 3); } }
      //Generate Excel File with given name.
 
      workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], { type: EXCEL_TYPE });
        var d = new Date, dformat = [d.getDate(), d.getMonth() + 1, d.getFullYear()].join('/') + ' ' + [d.getHours(), d.getMinutes(), d.getSeconds()].join(':');
        FileSaver.saveAs(blob, excelFileName + '_export_' + dformat + EXCEL_EXTENSION);
      })

    }
  }

}












