
import { NgModule } from '@angular/core';
import { ToastrModule } from 'ngx-toastr'; 
import { AppComponent } from './app.component';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { MainoutletComponent } from './mainoutlet/mainoutlet.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { HttpClientModule,HTTP_INTERCEPTORS } from '@angular/common/http';
import {MaterialModule} from './Shared/material.module';
// import { HttpErrorInterceptor } from './services/http-error.interceptor';
import {MatDialogModule} from '@angular/material/dialog'; 
import {CommonService } from './services/common.service'
import { ApiCallService} from './services/api-call.service'
import {LoginComponent} from './login/login.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { XlsxToJsonService } from './services/xls-to-json.service';
import { JsonToXlsxService } from './services/json-to-xlsx.service';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MY_DATE_FORMATS, MyDateAdapter } from './services/my-date-adapter';

@NgModule({
  declarations: [
    AppComponent,
    MainoutletComponent,
    LoginComponent,
    
  ],
  imports: [
    BrowserModule,
    MatDialogModule,
    CommonModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    HttpClientModule,
    ToastrModule.forRoot() ,// ToastrModule added
    NgxSpinnerModule,
  ],
  providers: [CommonService,ApiCallService,XlsxToJsonService,JsonToXlsxService,{ provide: MAT_DATE_LOCALE, useValue: 'en-GB' }, // Set your desired locale
  { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
  { provide: DateAdapter, useClass: MyDateAdapter }],
  bootstrap: [AppComponent]
})
export class AppModule { }
